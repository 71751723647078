import { useGetAccount } from "apollo/hooks/queries";
import { Result } from "apollo/utils";
import { capitaliseFirst } from "core/model/utils/strings";
import { Account } from "core/types";
import { CancelIcon, CheckIcon } from "ds/icons";
import TextInputField from "ds_legacy/components/TextInputField";
import {
  ERROR_COLOR,
  SUCCESS_COLOR,
  TEXT_DISABLED_COLOR,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { margin, padding } from "ds_legacy/materials/metrics";
import {
  Caption,
  FONT_SIZE_16,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import { useAcpNavigationHandlers } from "dsl/hooks";
import { useEffect, useState } from "react";
import {
  FormRenderProps,
  convertModelDefinition,
  valueDef,
} from "react-forms-state";
import { useSearchParams } from "react-router-dom";
import { DetailsTable, DetailsTableRow, SearchButton } from "./shared";

export default function AccountPage() {
  const [search, setSearch] = useSearchParams();
  const accountIdQueryparam = search.get("account_id");
  const [accountId, setAccountId] = useState<number | null>(null);

  const [queryProgress, account] = useGetAccount(accountId ?? -1);

  useEffect(() => {
    if (accountIdQueryparam) {
      setAccountId(Number(accountIdQueryparam));
    } else {
      setAccountId(null);
    }
  }, [accountIdQueryparam]);

  return (
    <>
      <HorizontalLayout aligned>
        <FormRenderProps
          formInputValue={{ account_id: accountId }}
          modelDefinition={convertModelDefinition({
            ...valueDef("account_id"),
          })}
          onSubmit={({ account_id }: { account_id: string }) => {
            if (account_id) {
              setAccountId(Number(account_id));
              search.set("account_id", account_id);
              setSearch(search);
            }
          }}
        >
          {({ submit }) => (
            <>
              <TextInputField
                elementName="account_id"
                placeholder="Account ID"
              />
              <SearchButton submit={submit} />
            </>
          )}
        </FormRenderProps>
      </HorizontalLayout>
      <Result
        data={account}
        ErrorComponent={<Caption>Could not get account {accountId}</Caption>}
        id="acp-seald-getaccount"
        queryProgress={queryProgress}
      >
        {(account) => <AccountDetails account={account} />}
      </Result>
    </>
  );
}

function AccountDetails({ account }: { account: Account }) {
  const {
    seald_database_key,
    seald_registered,
    seald_test_identity,
    seald_two_man_rule_key,
    seald_user_license_token,
  } = account;
  const sealdProps = {
    seald_database_key,
    seald_registered,
    seald_test_identity,
    seald_two_man_rule_key,
    seald_user_license_token,
  };
  if (!seald_test_identity) delete sealdProps.seald_test_identity;

  return (
    <>
      <Title>
        Account {account.id} - {account.email}
      </Title>
      <DetailsTable>
        <tbody>
          <DetailsTableRow>
            <td
              style={{
                padding: padding(0),
              }}
            >
              <Subheading>encryption activated for account</Subheading>
            </td>
            <td
              style={{
                padding: padding(0, 4),
              }}
            >
              {seald_registered || seald_test_identity ? (
                <CheckIcon
                  style={{ color: SUCCESS_COLOR }}
                  size={FONT_SIZE_16}
                />
              ) : (
                <CancelIcon
                  style={{ color: ERROR_COLOR }}
                  size={FONT_SIZE_16}
                />
              )}
            </td>
            <td
              style={{
                padding: padding(2, 4),
              }}
            >
              <VerticalLayout>
                {Object.entries(sealdProps).map(([key, value]) => (
                  <Caption key={key} color={TEXT_DISABLED_COLOR}>
                    {key}: {value ? "true" : "false"}
                  </Caption>
                ))}
              </VerticalLayout>
            </td>
          </DetailsTableRow>
        </tbody>
      </DetailsTable>
      <HorizontalLayout>
        <VerticalLayout margin={margin(0, 6)} style={{ flex: 1 }}>
          <RolesColumn
            type="sender"
            entityRoles={account.roles?.careseeker_roles
              ?.clone()
              .sort((a, b) => (a.careseeker?.id ?? 0) - (b.careseeker?.id ?? 0))
              .map((role) => ({
                id: role.careseeker?.id ?? -1,
                name: role.careseeker?.name ?? "",
              }))}
          />
        </VerticalLayout>
        <VerticalLayout margin={margin(0, 6)} style={{ flex: 1 }}>
          <RolesColumn
            type="receiver"
            entityRoles={account.roles?.careprovider_roles
              ?.clone()
              .sort(
                (a, b) => (a.careprovider?.id ?? 0) - (b.careprovider?.id ?? 0),
              )
              .map((role) => ({
                id: role.careprovider?.id ?? -1,
                name: role.careprovider?.name ?? "",
              }))}
          />
        </VerticalLayout>
      </HorizontalLayout>
    </>
  );
}

function RolesColumn({
  entityRoles,
  type,
}: {
  entityRoles: Array<{ id: number; name: string }> | undefined;
  type: "receiver" | "sender";
}) {
  const navigationHandlers = useAcpNavigationHandlers();
  return (
    <>
      <Title>{capitaliseFirst(type)} roles</Title>
      {entityRoles?.length ? (
        <DetailsTable>
          <tbody>
            {entityRoles.map(({ id, name }) => (
              <DetailsTableRow
                key={`role-${type}-${id}`}
                onClick={() => {
                  const page =
                    type === "sender"
                      ? `sender?careseeker_id=${id}`
                      : `receiver?careprovider_id=${id}`;
                  navigationHandlers.goToSealdDebug(page);
                }}
              >
                <td
                  style={{
                    padding: padding(0.5, 1),
                  }}
                >
                  <Caption whiteSpace="normal">
                    #{id} - {name}
                  </Caption>
                </td>
              </DetailsTableRow>
            ))}
          </tbody>
        </DetailsTable>
      ) : (
        <Caption>none</Caption>
      )}
    </>
  );
}
