import { Dispatch, SetStateAction, useEffect } from "react";
import { getChecks } from ".";
import { FormTypeValidation } from "../PasswordChangePage";
import { useTranslations } from "translations";

export const useValidatePassword = ({
  password,
  passwordConfirmation,
  setValidations,
}: {
  password: string | undefined;
  passwordConfirmation: string | undefined;
  setValidations: Dispatch<SetStateAction<FormTypeValidation[]>>;
}) => {
  const translations = useTranslations();
  useEffect(() => {
    const validations = getChecks(translations)
      .filter((val) => !val.check?.test(password ?? ""))
      .map((val) => ({
        description: val.description,
        key: val.key,
      }));

    if (password === "" || password != passwordConfirmation) {
      validations.push({
        description: translations.login.passwordsMustMatch,
        key: "must-match",
      });
    }
    setValidations(validations);
  }, [password, passwordConfirmation, translations]);
};
