import { isLoading } from "apollo/utils";
import { useEnvContext } from "context/EnvContext";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import auth from "core/model/api/endpoints/auth";
import { generateSaltedHash } from "core/model/crypto";
import { isNewTheme } from "core/model/utils/featureFlags";
import { computeSealdDisplayId, getSealdSDKInstance } from "core/seald";
import { QueryProgress } from "core/types";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { State } from "reduxentities/selectors/index";
import { useLocale, useTranslations } from "translations";
import { getTimestamp } from "../../organisms/Careseeker/UserManagementPage/useUserManagementFeature";

export type PasswordChangeErrorState =
  | {
      current_password?: string;
      general?: string;
    }
  | undefined;

type PasswordView = "default" | "success";

export const isPasswordChangeDefaultView = (view: PasswordView) =>
  view === "default";

export function useChangePassword() {
  const [changePasswordView, setChangePasswordView] =
    useState<PasswordView>("default");
  const translations = useTranslations();
  const account = useSelector(
    (state: State) => state.auth?.identification?.account,
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const [errors, setErrors] = useState<PasswordChangeErrorState>(undefined);
  const toast = useToast();

  const token = useSelector((state: State) => state.auth?.credentials?.token);
  const envContext = useEnvContext();
  const locale = useLocale();

  const resetError = useCallback(() => {
    setErrors(undefined);
  }, [setErrors]);

  const handleSubmit = useCallback(
    async ({
      current_password,
      new_password,
    }: {
      current_password: string;
      new_password: string;
    }) => {
      if (!account) return;

      setLoading(QUERY_PROGRESS_PENDING);

      try {
        let salt, old_password_hashed, new_password_hashed;

        const userId = computeSealdDisplayId({
          id: account.id,
          type: "account",
          envContext,
        });
        const { salt: querySalt } = await auth.getSalt({
          user: account.email || "",
        });
        if (querySalt) {
          salt = querySalt;
          old_password_hashed = await generateSaltedHash(
            current_password,
            querySalt,
          );
          new_password_hashed = await generateSaltedHash(
            new_password,
            querySalt,
          );
        }

        // Change password in Seald
        const sealdSDKInstance = await getSealdSDKInstance();
        if (sealdSDKInstance && token) {
          const { has_password } = await auth.checkIdentity(token);
          if (has_password) {
            await sealdSDKInstance.ssksPassword.changeIdentityPassword({
              currentPassword: current_password,
              newPassword: new_password,
              userId,
            });
          }
        }

        await auth.inAppChangePassword({
          id: account.id,
          old_password_hashed,
          new_password_hashed,
          salt,
          timestamp: getTimestamp(locale),
        });

        setLoading(QUERY_PROGRESS_SUCCEED);
        if (isNewTheme()) {
          setChangePasswordView("success");
        } else {
          toast({
            color: "success",
            message: translations.login.passwordResetSuccessfully,
          });
          setTimeout(() => {
            navigate("/logout?reason=password_change");
          }, 2000);
        }
      } catch (e: any) {
        setLoading(QUERY_PROGRESS_FAILED);
        const errorString = e.toString();

        if (
          errorString.includes("Password is invalid") ||
          errorString.includes("Bad password") ||
          errorString.includes(
            "Couldn't find identity with this id/password combination",
          )
        ) {
          setErrors((prev) => ({
            ...(prev ?? {}),
            current_password: translations.login.passwordChangeFailure,
          }));
        } else if (errorString.includes("throttled")) {
          setErrors((prev) => ({
            ...(prev ?? {}),
            general: translations.login.loginPage.passwordAttemptError,
          }));
        } else {
          setErrors((prev) => ({
            ...(prev ?? {}),
            general: translations.general.status.error,
          }));
        }
      }
    },
    [
      account,
      auth.checkIdentity,
      auth.getSalt,
      auth.inAppChangePassword,
      generateSaltedHash,
      getSealdSDKInstance,
      getTimestamp,
      locale,
      navigate,
      setErrors,
      setLoading,
      toast,
      token,
      translations,
    ],
  );

  return {
    changePasswordView,
    errors,
    handleSubmit,
    isLoading: isLoading(loading),
    resetError,
  };
}
