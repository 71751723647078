import { isLoading } from "apollo/utils";
import { useEnvContext } from "context/EnvContext";
import { APP_ACP, REDIRECT_PARAM } from "core/consts";
import { isAdminLogged } from "core/model/accounts";
import { activateEnvSwitch, isNewTheme } from "core/model/utils/featureFlags";
import { getQueryVariable } from "core/model/utils/urls";
import { useManualSetLegalDocuments } from "dsl/atoms/LegalDocuments";
import { LoginPageView } from "dsl/ecosystems/LoginPage";
import { useHandleLogin } from "dsl/ecosystems/LoginPage/useHandleLogin";
import { useLoginPage } from "dsl/ecosystems/LoginPage/useLoginPage";
import { LoginPageV2 } from "dsl/ecosystems/LoginPageV2";
import {
  loginPageModelDefinition,
  validateChallenge,
  validateLogin,
} from "dsl/ecosystems/LoginPageV2/formConfig";
import { useAcpNavigationHandlers } from "dsl/hooks";
import { useAcpHandlerUrls } from "dsl/hooks/useNavigationHandlers";
import { acpAuthPath, defaultRedirectPath } from "entries/acp/Router";
import { ReactNode } from "react";
import { SimpleFormRenderProps } from "react-forms-state/src";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

export default function AcpAuthPage() {
  const logged = useSelector(isAdminLogged);
  const location = useLocation();
  const redirectTo = getQueryVariable(location.search, REDIRECT_PARAM);
  const { goToForgotPassword } = useAcpNavigationHandlers();
  const { env } = useEnvContext();
  const {
    challenge,
    email,
    error,
    loginProgress,
    module,
    oauthToken,
    onLogin,
    password,
    setChallenge,
    setEmail,
    setModule,
    setPassword,
    status,
  } = useLoginPage();
  const {
    challengeTimestamp,
    handleLogin,
    isLogin,
    loginErrors,
    loginView,
    progressLogin,
    resetError,
    setLoginView,
    setProgressLogin,
  } = useHandleLogin();
  useManualSetLegalDocuments();
  const { goToForgotPassword: goToForgotPasswordUrl, goToProductionEnv } =
    useAcpHandlerUrls();

  return logged === "ok" ? (
    <Navigate to={redirectTo ?? defaultRedirectPath} replace />
  ) : (
    <>
      {isNewTheme() ? (
        <main>
          <SimpleFormRenderProps
            asHtmlForm
            onSubmit={(props) => handleLogin(props, setProgressLogin)}
            modelDefinition={loginPageModelDefinition}
            validate={isLogin ? validateLogin : validateChallenge}
          >
            {({ onChange, submit }) => (
              <LoginPageV2
                app={APP_ACP}
                challengeTimestamp={challengeTimestamp}
                env={env}
                goToForgotPassword={goToForgotPasswordUrl()}
                goToProductionUrl={goToProductionEnv()}
                handleLogin={(props) => handleLogin(props, setProgressLogin)}
                isLoading={isLoading(progressLogin)}
                loginErrors={loginErrors}
                loginView={loginView}
                onBackToLogin={() => {
                  onChange(null, "challenge", true);
                  onChange(null, "email", true);
                  onChange(null, "password", true);
                  resetError();
                  setLoginView("login");
                }}
                onChangeFormValues={onChange}
                onSubmit={submit}
                resetError={resetError}
                withDevTools={activateEnvSwitch}
              />
            )}
          </SimpleFormRenderProps>
        </main>
      ) : (
        <LoginPageView
          challenge={challenge}
          email={email}
          error={error ?? null}
          module={module}
          onLogin={onLogin}
          onLoginQueryProgress={loginProgress}
          password={password}
          passwordRequired={!oauthToken}
          setChallenge={setChallenge}
          setEmail={setEmail}
          setModule={setModule}
          setPassword={setPassword}
          status={status}
          onPasswordResetPage={() => goToForgotPassword()}
        />
      )}
    </>
  );
}

export function AcpAuthenticate({ children }: { children: ReactNode | null }) {
  const logged = useSelector(isAdminLogged);
  const { pathname, search } = useLocation();
  const redirectTo = encodeURIComponent(`${pathname}${search}`);

  return logged === "ok" ? (
    <>{children}</>
  ) : (
    <Navigate
      to={{ pathname: acpAuthPath, search: `${REDIRECT_PARAM}=${redirectTo}` }}
      replace
    />
  );
}
