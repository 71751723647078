import { useApolloClient } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useUpdateAccount } from "apollo/hooks/mutations";
import { useEnvContext } from "context/EnvContext";
import {
  ACCOUNT_STATUS_INACTIVE,
  ACCOUNT_TYPE_PROVIDER_SEARCH,
  ACCOUNT_TYPE_STAFF,
  APP_CAREPROVIDER,
  APP_CLINIC,
  TRACK_EVENTS,
} from "core/consts";
import api from "core/model/api";
import auth from "core/model/api/endpoints/auth";
import { getFirstProviderRole } from "core/model/careproviders";
import Config from "core/model/config";
import { generateSaltedHash, hasCrypto } from "core/model/crypto";
import { getPrivateKey } from "core/model/crypto/cryptoService";
import { getError } from "core/model/utils/errors";
import {
  activateRealUserMonitoring,
  activateSeald,
} from "core/model/utils/featureFlags";
import { isDev } from "core/seald";
import { useSealdContext } from "core/seald/SealdContext";
import { CareproviderRoles, SsoTokenDecoded } from "core/types";
import { differenceInMilliseconds } from "date-fns";
import { getSubscriptionInfoWithTimeout } from "dsl/atoms/BrowserNotificationContext/NotificationPermission";
import gql from "graphql-tag";
import jwtDecode from "jwt-decode";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";

export type LoginProps = {
  careproviderToLog?: number;
  challenge?: string;
  password: string;
  password_hashed?: string;
  salt?: string;
  token?: string;
  username: string;
};

export type LoginFnError = {
  message?: string;
  needsChallenge?: boolean;
};

export type LoginFn = (props: LoginProps) => Promise<LoginFnError | undefined>;

type AuthenticateProps = {
  password: string;
  password_hashed: string | undefined;
  salt: string | undefined;
  username: string;
};

let tentativeLoginAccountId: number | undefined;
const setTentativeLoginAccountId = (accountId: number) => {
  tentativeLoginAccountId = accountId;
};

export const getTentativeLoginAccountId = () => tentativeLoginAccountId;

export function activateEncryption(
  currentLocation: AnyObject | null,
): [boolean, { hostname?: string; protocol?: string; reason?: string }] {
  if (!currentLocation) return [false, { reason: "no location" }];

  const correctLocation =
    currentLocation.protocol === "https:" ||
    // testcafe
    currentLocation.hostname.startsWith("10.0") ||
    currentLocation.hostname === "localhost";
  if (!correctLocation)
    return [
      false,
      {
        reason: "invalid location",
        protocol: currentLocation.protocol,
        hostname: currentLocation.hostname,
      },
    ];

  if (!hasCrypto()) {
    return [
      false,
      {
        reason: "no crypto",
      },
    ];
  }

  return [true, {}];
}

export async function getLoginArguments({
  careproviderToLog,
  challenge,
  password,
  token,
  username,
}: Omit<LoginProps, "salt" | "password_hashed">): Promise<LoginProps> {
  const loginArgs: LoginProps = {
    username,
    password,
    challenge: challenge ?? undefined,
    careproviderToLog,
    token,
    password_hashed: undefined,
    salt: undefined,
  };

  if (!token) {
    const { salt } = await auth.getSalt({ user: username });
    const password_hashed = await generateSaltedHash(password, salt);
    return {
      ...loginArgs,
      password_hashed,
      salt,
    };
  }

  return loginArgs;
}

async function handleAuthentication({
  password,
  password_hashed,
  salt,
  username: user,
}: AuthenticateProps) {
  if (password_hashed && salt) {
    return auth.authenticate({
      user,
      salt,
      password_hashed,
    });
  }

  const { salt: genSalt } = await auth.getSalt({ user });
  const passwordHashed = await generateSaltedHash(password, genSalt);

  return auth.authenticate({
    user,
    salt: genSalt,
    password_hashed: passwordHashed,
  });
}

function getTokenExpiration(token: string | undefined) {
  return token ? jwtDecode<SsoTokenDecoded>(token).exp : undefined;
}

export function useLogin() {
  const client = useApolloClient();
  const translations = useTranslations();
  const { app, env } = useEnvContext();
  const { trackEvent } = useTracking();
  const dispatch = useDispatch();
  const [updateAccount] = useUpdateAccount();
  const importSealdIdentity = useSealdContext()?.importSealdIdentity;

  const login = useCallback(
    async ({
      careproviderToLog,
      challenge,
      password,
      password_hashed,
      salt,
      token: ssoToken,
      username,
    }: LoginProps): Promise<undefined | LoginFnError> => {
      if (isDev(env) && !process.env.PR_NAME) {
        const res = await fetch(
          "https://api-staging.recaresolutions.com/cleandata?check",
        );
        const text = await res.text();
        if (text.includes("already running")) {
          return Promise.reject<{ message: string }>({
            message: "cleandata in progress",
          });
        }
      }

      const loginStartTime = new Date();

      let token = ssoToken;
      let expiration = getTokenExpiration(token);

      if (!token) {
        if (!password) {
          dispatch({ type: "LOGGED_OUT", payload: {} });
          throw new Error("No token and no password");
        }
        const authenticationResult = await handleAuthentication({
          username,
          password,
          password_hashed,
          salt,
        });
        token = authenticationResult.token;
        expiration = authenticationResult.expiration;
      }

      const subscription_info = await getSubscriptionInfoWithTimeout();

      const {
        account,
        admin_roles,
        careprovider_roles,
        careseeker_roles,
        disable_seald,
        seald_flow,
      } = await auth.login({
        token,
        activateSeald: activateSeald(app),
        subscription_info,
      });

      setTentativeLoginAccountId(account.id);

      await client?.clearStore();

      if (account.status === ACCOUNT_STATUS_INACTIVE) {
        dispatch({ type: "LOGGED_OUT", payload: {} });
        throw new Error(translations.login.toastInactiveProvider);
      }

      if (
        (app === APP_CLINIC &&
          ((!careseeker_roles?.length && !admin_roles?.length) ||
            account.account_type === ACCOUNT_TYPE_PROVIDER_SEARCH)) ||
        (app === APP_CAREPROVIDER &&
          !careprovider_roles?.length &&
          !admin_roles?.length)
      ) {
        dispatch({ type: "LOGGED_OUT", payload: {} });
        throw new Error(translations.login.toastInactiveProvider);
      }

      let privateKey;
      let trackContext: AnyObject = {};

      const [activate, context] = activateEncryption(location);

      if (!activate) {
        trackEvent({
          name: TRACK_EVENTS.ENCRYPTION_DEACTIVATED,
          ...context,
        });
      } else {
        trackEvent({ name: TRACK_EVENTS.ENCRYPTION_ACTIVATED });

        if (password) {
          const [decryptedPrivateKey, privateKeyContext] = await getPrivateKey(
            account,
            password,
            {
              api,
              trackEvent,
              token,
            },
          );
          privateKey = decryptedPrivateKey;
          trackContext = {
            decrypted: privateKeyContext.decrypted,
            generated: privateKeyContext.generated,
          };

          if (!privateKey) {
            dispatch({ type: "LOGGED_OUT", payload: {} });
            throw new Error("no private key " + JSON.stringify(context));
          }
        }
      }

      if (careseeker_roles?.length) {
        const careseeker = {
          id: careseeker_roles[0].careseeker?.id,
          __typename: "Careseeker",
        };
        const query = gql`
          query Careseekers {
            careseekers {
              id
            }
          }
        `;
        client?.writeQuery({
          query,
          data: {
            careseekers: [careseeker],
          },
        });
      }

      let validCareproviderToLog = false;

      if (careprovider_roles?.length) {
        let careproviderData = getFirstProviderRole(careprovider_roles);
        if (careproviderToLog) {
          const filteredRoles = careprovider_roles.filter(
            (role: CareproviderRoles) =>
              role.careprovider?.id === careproviderToLog,
          );

          if (filteredRoles.length === 1) {
            validCareproviderToLog = true;
            careproviderData = filteredRoles[0];
          }
        }

        const careprovider = {
          id: careproviderData?.careprovider?.id,
          __typename: "Careprovider",
        };

        const query = gql`
          query Careproviders {
            careproviders {
              id
            }
          }
        `;
        client?.writeQuery({
          query,
          data: {
            careproviders: [careprovider],
          },
        });
      }

      if (
        !validCareproviderToLog &&
        account.account_type === ACCOUNT_TYPE_STAFF
      ) {
        validCareproviderToLog = true;
        careproviderToLog = careproviderToLog || 1;
      }

      // ********************** SEALD **********************
      if (activateSeald(app) && !disable_seald && importSealdIdentity) {
        console.log("[Seald] account", { account });
        console.log("[Seald] challenge", challenge);
        let mustAuthenticate;
        try {
          mustAuthenticate = await importSealdIdentity({
            account,
            seald_flow,
            challenge,
            password,
            token,
            updateAccount,
          });
        } catch (err) {
          const error = getError(err);

          trackEvent({
            name: TRACK_EVENTS.SEALD_FLOW_FAILED,
            error_message: error.message,
            user_information: {
              account_id: account.id,
            },
          });

          const tags = `[account_id:${
            account.id
          }][seald_flow:${seald_flow}][challenge:${challenge ? "yes" : "no"}]`;

          console.error(`Seald flow failed: ${tags} ${error.message}`, error);

          if (activateRealUserMonitoring) {
            datadogRum.addError(err);
          }

          if (error.message.includes("reset_identity")) {
            // prevent login page loading state from flickering
            await new Promise((r) => setTimeout(() => r(null), 5000));
            return Promise.reject({});
          } else {
            dispatch({ type: "LOGGED_OUT", payload: {} });
            throw err;
          }
        }
        console.log("[Seald] mustAuthenticate", mustAuthenticate);
        if (mustAuthenticate) {
          return Promise.reject<{ needsChallenge: true }>({
            needsChallenge: true,
          });
        }
      }

      // ********************** /SEALD **********************
      dispatch({
        type: "TOKEN_CHANGED",
        payload: {
          auth_type: password ? "password" : "sso",
          version: Config.version,
          token,
          token_type: "jwt",
          expiration,
          identification: {
            privateKey,
            account,
            careprovider_roles,
            careseeker_roles,
            admin_roles,
          },
          careproviderToLog: validCareproviderToLog
            ? careproviderToLog
            : undefined,
        },
      });
      trackEvent({
        name: TRACK_EVENTS.USER_LOGIN_DURATION,
        duration: differenceInMilliseconds(new Date(), loginStartTime),
        ...trackContext,
      });
    },
    [
      app,
      client,
      dispatch,
      env,
      getTokenExpiration,
      importSealdIdentity,
      trackEvent,
      translations,
      updateAccount,
    ],
  );

  return login;
}
