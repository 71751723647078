import { useCheckSealdSessions } from "apollo/hooks/mutations";
import { useGetFilesQuery, useGetPatientQuery } from "apollo/hooks/queries";
import { Result, composeProgress } from "apollo/utils";
import { ONTOLOGY_FILE_CATEGORY } from "core/model/utils/ontologies";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { capitaliseFirst } from "core/model/utils/strings";
import { Auction, Patient, SealdSessionAccess } from "core/types";
import { format, fromUnixTime } from "date-fns";
import { CancelIcon, CheckIcon } from "ds/icons";
import InfoChip from "ds_legacy/components/InfoChip";
import TextInputField from "ds_legacy/components/TextInputField";
import {
  ERROR_COLOR,
  SUCCESS_COLOR,
  TEXT_DISABLED_COLOR,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { padding } from "ds_legacy/materials/metrics";
import {
  Caption,
  FONT_SIZE_16,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import { useAcpNavigationHandlers } from "dsl/hooks";
import { useEffect, useState } from "react";
import {
  FormRenderProps,
  convertModelDefinition,
  valueDef,
} from "react-forms-state";
import { useSearchParams } from "react-router-dom";
import { DetailsTable, DetailsTableRow, SearchButton } from "./shared";

const formatDate = (createdAt: number | null) =>
  createdAt
    ? format(fromUnixTime(createdAt), "dd/MM/yy - HH:mm:ss x")
    : "false";

export default function SessionPage({
  page,
}: {
  page: "file" | "patient" | "request";
}) {
  const [search, setSearch] = useSearchParams();
  const [sessionId, setSessionId] = useState<number | null>(null);

  const [patientQueryProgress, patient] = useGetPatientQuery({
    patientId: sessionId ?? -1,
    options: { skip: page !== "patient" || !sessionId },
  });
  const [
    checkSealdSessions,
    checkSealdSessionsQueryProgress,
    _,
    sealdSessionsPayload,
  ] = useCheckSealdSessions();

  const sealdSessions = sealdSessionsPayload
    ? sealdSessionsPayload.sealdSessions
    : undefined;

  const queryParamName =
    page === "patient"
      ? "patient_id"
      : page === "request"
      ? "auction_request_id"
      : "file_id";
  const sessionIdQueryparam = search.get(queryParamName);

  useEffect(() => {
    if (sessionIdQueryparam) {
      setSessionId(Number(sessionIdQueryparam));
    } else {
      setSessionId(null);
    }
  }, [sessionIdQueryparam]);

  useEffect(() => {
    if (sessionId) {
      checkSealdSessions({ [queryParamName]: sessionId });
    }
  }, [queryParamName, sessionId]);

  return (
    <>
      <HorizontalLayout aligned>
        <FormRenderProps
          formInputValue={{
            session_id: sessionId,
          }}
          modelDefinition={convertModelDefinition({
            ...valueDef("session_id"),
          })}
          onSubmit={({ session_id }: { session_id: string }) => {
            if (session_id) {
              setSessionId(Number(session_id));
              search.set(queryParamName, session_id);
              setSearch(search);
            }
          }}
        >
          {({ submit }) => (
            <>
              <TextInputField
                elementName="session_id"
                placeholder={`${capitaliseFirst(page)} ID`}
              />
              <SearchButton submit={submit} />
            </>
          )}
        </FormRenderProps>
      </HorizontalLayout>
      <Result
        data={{}}
        id="seald-session"
        ErrorComponent={
          <Caption>
            Could not get {page} {sessionId}
          </Caption>
        }
        queryProgress={composeProgress([
          patientQueryProgress,
          checkSealdSessionsQueryProgress,
        ])}
      >
        {() => (
          <>
            <Title>
              {capitaliseFirst(page)} {sessionId}
            </Title>
            <DetailsTable>
              <tbody>
                <DetailsTableRow>
                  <td
                    style={{
                      padding: padding(0),
                    }}
                  >
                    <Subheading>encryption activated for {page}</Subheading>
                  </td>
                  <td
                    style={{
                      padding: padding(0, 4),
                    }}
                  >
                    {sealdSessions?.seald_encryption_context_created_at ? (
                      <HorizontalLayout aligned>
                        <CheckIcon
                          style={{ color: SUCCESS_COLOR }}
                          size={FONT_SIZE_16}
                        />
                        <Caption>
                          {formatDate(
                            sealdSessions.seald_encryption_context_created_at,
                          )}
                        </Caption>
                      </HorizontalLayout>
                    ) : (
                      <CancelIcon
                        style={{ color: ERROR_COLOR }}
                        size={FONT_SIZE_16}
                      />
                    )}
                  </td>
                </DetailsTableRow>
              </tbody>
            </DetailsTable>
            <Subheading>Sender accesses:</Subheading>
            {sealdSessions?.careseeker_accesses?.length ? (
              <AccessesDetailsTable
                accesses={sealdSessions.careseeker_accesses}
                type="sender"
              />
            ) : (
              <Caption>none</Caption>
            )}
            <Subheading>Receiver accesses:</Subheading>
            {sealdSessions?.careprovider_accesses?.length ? (
              <AccessesDetailsTable
                accesses={sealdSessions.careprovider_accesses}
                type="receiver"
              />
            ) : (
              <Caption>none</Caption>
            )}
            {page === "patient" && patient && (
              <FilesForPatient patient={patient} />
            )}
          </>
        )}
      </Result>
    </>
  );
}

function AccessesDetailsTable({
  accesses,
  type,
}: {
  accesses: Array<SealdSessionAccess>;
  type: "receiver" | "sender";
}) {
  const navigationHandlers = useAcpNavigationHandlers();
  return (
    <DetailsTable>
      <tbody>
        {accesses.map((access) => (
          <DetailsTableRow
            key={access.name}
            onClick={() => {
              const page =
                type === "sender"
                  ? `sender?careseeker_id=${access.id}`
                  : `receiver?careprovider_id=${access.id}`;
              navigationHandlers.goToSealdDebug(page);
            }}
          >
            <td
              style={{
                padding: padding(0),
              }}
            >
              <Subheading>
                #{access.id} {access.name}
              </Subheading>
            </td>
            <td
              style={{
                padding: padding(0, 4),
              }}
            >
              {access.seald_db_access_created_at ? (
                <HorizontalLayout aligned>
                  <CheckIcon
                    style={{ color: SUCCESS_COLOR }}
                    size={FONT_SIZE_16}
                  />
                  <Caption>
                    {formatDate(access.seald_db_access_created_at)}
                  </Caption>
                </HorizontalLayout>
              ) : (
                <CancelIcon
                  style={{ color: ERROR_COLOR }}
                  size={FONT_SIZE_16}
                />
              )}
            </td>
            <td
              style={{
                padding: padding(2, 4),
              }}
            >
              <VerticalLayout>
                <Caption color={TEXT_DISABLED_COLOR}>
                  seald_db_access:{" "}
                  {formatDate(access.seald_db_access_created_at)}
                </Caption>
                <Caption color={TEXT_DISABLED_COLOR}>
                  seald_access: {access.seald_access ? "true" : "false"}
                </Caption>
                <Caption color={TEXT_DISABLED_COLOR}>
                  pending_access: {formatDate(access.pending_access_created_at)}
                </Caption>
              </VerticalLayout>
            </td>
          </DetailsTableRow>
        ))}
      </tbody>
    </DetailsTable>
  );
}

function FilesForPatient({ patient }: { patient: Patient }) {
  return (
    <VerticalLayout>
      {patient.auctions?.map((auction) => (
        <FilesForAuction key={auction.id} auction={auction} />
      ))}
    </VerticalLayout>
  );
}

function FilesForAuction({ auction }: { auction: Auction }) {
  const navigationHandlers = useAcpNavigationHandlers();
  const getOntology = useGetOntology();
  const [filesQueryProgress, files] = useGetFilesQuery({
    params: `auction_id=${auction.id}`,
  });
  return (
    <>
      <Result
        data={files}
        id="seald-session"
        ErrorComponent={
          <Caption>Could not get files for auction {auction.id}</Caption>
        }
        queryProgress={filesQueryProgress}
      >
        {(files) => (
          <>
            <Title>Files for search {auction.id}</Title>
            {files.length ? (
              <DetailsTable>
                <tbody>
                  {files.map((file) => (
                    <DetailsTableRow
                      key={`file-${file.id}`}
                      onClick={() => {
                        navigationHandlers.goToSealdDebug(
                          `file?file_id=${file.id}`,
                        );
                      }}
                    >
                      <td
                        style={{
                          padding: padding(0),
                        }}
                      >
                        <Subheading>#{file.id}</Subheading>
                      </td>
                      <td
                        style={{
                          padding: padding(0, 4),
                        }}
                      >
                        <InfoChip
                          label={getOntology({
                            key: file.category,
                            type: ONTOLOGY_FILE_CATEGORY,
                            useShortValues: true,
                          })}
                          tooltip={getOntology({
                            key: file.category,
                            type: ONTOLOGY_FILE_CATEGORY,
                          })}
                        />
                      </td>
                      <td
                        style={{
                          padding: padding(0, 4),
                        }}
                      >
                        <Caption>{formatDate(file.created_at)}</Caption>
                      </td>
                    </DetailsTableRow>
                  ))}
                </tbody>
              </DetailsTable>
            ) : (
              <Caption>none</Caption>
            )}
          </>
        )}
      </Result>
    </>
  );
}
